import React from "react";
import { PageLayout } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import Margins from "@components/Margins/Margins";
import { Hero } from "@components/Hero/Hero";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import FeatureCard, { FeatureCardLayout, FeatureCardProps } from "@components/FeatureCard/FeatureCard";

export default function Insights() {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "version-control" }}>
			<Margins className={styles.margins}>
				<HeroSection />
				<SeamlessNavigation />
				<ConvenientLabel />
				<UniversalProtocol />
				<JoinInstitutionsV2 outerContainerClassName={styles.outerContainerClassName} />
			</Margins>
		</PageLayout>
	);
}


// New component redesign starts here
const HeroSection = () => {
	const hero = {
		title: "Effortless protocol management and collaboration",
		description:
			"Easily manage your protocol with advanced version control, editing, and shared access features.",
		image: (
			<StaticImage
				src="../../../assets/products/integration-hero.png"
				alt="A Central Protocol Hub for Smooth Collaboration"
			/>
		),
	};
	return (
		<Hero className={styles.hero} {...hero} heroTextContainerSize="small" tabletTextAlignment="left" />
	)
}

const SeamlessNavigation = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Seamless navigation and protocol versioning",
			image: (
				<StaticImage
					src={"../../../assets/products/preview-protocol-and-versions.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Our protocol library is easy to navigate and allows you to compare and switch between different versions without hassle",
				"Select and edit any protocol version your research requires",
				"The ability to quickly toggle between versions means you always have the right protocol on hand",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.container} {...card} key={index} orientation="left" />;
			})}
		</FeatureCardLayout>
	)
}

const ConvenientLabel = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Convenient labels to turbocharge teamwork",
			image: (
				<StaticImage
					src={"../../../assets/products/create-protocol-template-with-labels.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Create and edit documents with your team in real time",
				"The edit mode lets you make collaborative adjustments so that all your revisions are consistent",
				"Labels and tags help you organize and streamline both identification and future referencing",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.container} {...card} key={index} orientation="right" />;
			})}
		</FeatureCardLayout>
	)
}

const UniversalProtocol = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Universal protocol sharing",
			image: (
				<StaticImage
					src={"../../../assets/products/protocol-preview.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Keep your entire team aligned by sharing protocols with all members",
				"Each protocol version has a unique link, so that you maintain a singular, trusted source",
				"Foster collaborative insights so that everyone is up to date with the most recent and relevant protocol version",
			],
		}
	];
	return (
		<FeatureCardLayout className={styles.universalProtocolCards}>
			{cards.map((card, index) => {
				return <FeatureCard className={styles.container} {...card} key={index} orientation="left" />;
			})}
		</FeatureCardLayout>
	)
}
